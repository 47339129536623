import React from 'react' 
import styled from 'styled-components';

const Loading = () => {

    return (
        <>
    <div class="loader"></div>
        <LoadingText>Ditt email skickas, vänligen vänta...</LoadingText>
        </>
    )
}


export default Loading

const LoadingText = styled.p`
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 305;
    line-height: 1.8em;
    padding-left: 0.5rem;
`