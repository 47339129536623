import React from 'react';
import styled from 'styled-components';


const CookieInformation = () => {

    return (
        <Section>
            <InfoContainer>
                <Header>Information om Cookies</Header>
                    <Info>
                        För att göra användarupplevelsen för dig som besöker vår sida så bra som möjligt så använder vi Local Storage där vi sparar information om vilka produkter du lagt i din kundkorg, så att du kan återkomma till den vid ett senare tillfälle och ha kvar din önskelista utan att behöva skapa om den på nytt. Det förutsätter att du besöker oss igen på samma enhet och browser som förra gången.
                    </Info>
                    <Info>
                        Vi använder också Local Storage så att du inte ska behöva klicka i att du accepterar cookies varje gång du besöker vår hemsida, eftersom vi sparar information om att du redan har accepterat.
                    </Info>
            </InfoContainer>
        </Section>
    )
};

export default CookieInformation;


const Section = styled.section `
    display: flex;
    flex-direction: column;
`

const InfoContainer = styled.div `
    padding: 8rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    @media (min-width: 668px) {
        width: 800px;
    }

    @media (min-width: 900px) {
        width: 1000px;
        align-self: center;
        background-color: white;
        margin-top: 10rem;
        padding: 2rem;
    }
`

const Header = styled.h1 `
    text-transform: uppercase;
    font-weight: 300;
    padding: 3rem 0;
    font-size: 32px;
    letter-spacing: .15em;

    @media (min-width: 668px) {
        font-size: 32px;
        letter-spacing: .2em;
    }

`

const Info = styled.h4 `
    font-weight: 300;
    margin: 12px 15px;
    line-height: 28px;
    padding-bottom: 2.5rem;

    @media (min-width: 668px) {
        font-size: 16px;
        letter-spacing: .03em;
        padding-bottom: 1rem;
    }
    
    @media (min-width: 900px) {
        background-color: white;
    }
`